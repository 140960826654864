export const messagesMock = [
    {
        lastActivityDate: '2024-05-01T10:30:00Z',
        topic: 'Routine Checkup',
        patientName: 'John Doe',
        creationDate: '2024-04-15T09:00:00Z',
        clinicianName: 'Dr. Smith',
        unread: true,
        id: 'testId1',
        episodeId: 'testEpisodeId',
        patientId: '',
        userId: '',
        messages: [
            {
                messageId: 'message1',
                message: 'This is a reminder for the meeting scheduled at 3 PM today.',
                senderName: 'Dr. Smith',
                sendDateTime: '2024-05-01T09:30:00Z',
            },
            {
                messageId: 'message2',
                message: 'All right!',
                senderName: 'John Doe',
                sendDateTime: '2024-05-02T10:45:00Z',
            },
        ],
    },
    {
        lastActivityDate: '2024-05-02T14:20:00Z',
        topic: 'Follow-up Appointment',
        patientName: 'John Doe',
        creationDate: '2024-04-20T11:45:00Z',
        clinicianName: 'Dr. Adams',
        unread: false,
        id: 'testId2',
        episodeId: '',
        patientId: '',
        userId: '',
        messages: [
            {
                messageId: 'message1',
                message: 'This is a reminder for the meeting scheduled at 3 PM today.',
                senderName: 'Dr. Adams',
                sendDateTime: '2024-05-01T09:30:00Z',
            },
            {
                messageId: 'message2',
                message: 'All right!',
                senderName: 'John Doe',
                sendDateTime: '2024-05-02T10:45:00Z',
            },
        ],
    },
    {
        lastActivityDate: '2024-05-03T08:50:00Z',
        topic: 'Questionnaires Results',
        patientName: 'John Doe',
        creationDate: '2024-04-25T10:30:00Z',
        clinicianName: 'Dr. Clark',
        unread: false,
        id: 'testId3',
        episodeId: '',
        patientId: '',
        userId: '',
        messages: [
            {
                messageId: 'message1',
                message:
                    'We should tak about your results. Nam eu cursus felis, quis pulvinar lorem. Curabitur scelerisque lacus quam, quis tincidunt quam dapibus ac. In vitae velit aliquet, dapibus velit ut, sodales ipsum. Duis dictum velit sed mauris placerat, id convallis nisi finibus. Aenean nulla ligula, mollis non ullamcorper vehicula, imperdiet vel est. Cras ut dapibus lectus. Etiam malesuada ex massa, ut dictum nulla consectetur in',
                senderName: 'Dr. Clark',
                sendDateTime: '2024-05-03T11:30:00Z',
            },
            {
                messageId: 'message2',
                message: 'Sure thing',
                senderName: 'John Doe',
                sendDateTime: '2024-05-03T10:45:00Z',
            },
        ],
    },
];
