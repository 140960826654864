import { all } from 'redux-saga/effects';
import watchUnAuthorizedErrors from './service-client/saga';
import watchGetEpisodes from './containers/episodes/saga';
import watchCreateCalendarEvent from './containers/scheduler/calendar-event/saga';
import watchGetUsers from './containers/users/saga';
import watchGetPractices from './containers/practices/saga';
import watchGetLanguages from './containers/languages/saga';
import watchHydrateSchedulerFilter from './containers/scheduler/scheduler-filter/saga';
import {
    watchGetUserConcertSettings,
    watchSaveUserConcertSettings,
    watchSaveUserPractices,
} from './containers/settings/other/saga';
import { watchGetSettingsUser } from './containers/settings/saga';
import watchGetEngagementTypes from './containers/engagement-types/saga';

export default function* rootSaga(): Generator {
    yield all([
        watchUnAuthorizedErrors(),
        watchGetEpisodes(),
        watchCreateCalendarEvent(),
        watchGetUsers(),
        watchGetPractices(),
        watchGetLanguages(),
        watchHydrateSchedulerFilter(),
        watchGetUserConcertSettings(),
        watchSaveUserConcertSettings(),
        watchGetSettingsUser(),
        watchSaveUserPractices(),
        watchGetEngagementTypes(),
    ]);
}
