import { Card } from 'primereact/card';
import { FunctionComponent } from 'react';
import './message-center.scss';
import { Conversation, ConversationMessage } from './conversation';
import { MessageSender, MessageSenderProps } from './message-sender';

export interface ConversationViewerProps {
    conversation: Conversation | null;
    messageSenderComponentProps: MessageSenderProps;
}
export const ConversationViewer: FunctionComponent<ConversationViewerProps> = ({
    conversation,
    messageSenderComponentProps,
}) => {
    const newConversationTitle = 'New Conversation';
    const messageRender = (message: ConversationMessage) => {
        return (
            <>
                <div className="grid-column left-column">
                    <div className="sender-container">{message.senderName}</div>
                    <div className="send-time-container">{new Date(message.sendDateTime).toUTCString()}</div>
                </div>
                <div className="grid-column right-column">{message.message}</div>
            </>
        );
    };
    const messageThreadRender = () => {
        return conversation?.messages.map((m: ConversationMessage) => messageRender(m));
    };
    return (
        <div className="app-container message-center-container">
            <h2>{conversation?.topic ?? newConversationTitle}</h2>
            <Card>
                <MessageSender
                    isNewMessage={messageSenderComponentProps.isNewMessage}
                    handleMessageSubmission={messageSenderComponentProps.handleMessageSubmission}
                    userType={messageSenderComponentProps.userType}
                />
                <div className="message-container">{messageThreadRender()}</div>
            </Card>
        </div>
    );
};
