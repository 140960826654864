import { graphql } from 'msw';
import {
    GetAuthenticationTokenQuery,
    GetAuthenticationTokenQueryVariables,
    GetUserAvailabilitySettingsQuery,
    GetUserAvailabilitySettingsQueryVariables,
    GetPatientAvailabilitySettingsQuery,
    GetPatientAvailabilitySettingsQueryVariables,
    ScheduleAvailabilityQuery,
    ScheduleAvailabilityQueryVariables,
} from '../services/graphql/generated';
import { AvailabilityWhoFilterType } from '../containers/scheduler/scheduler-filter/slice';

const mainProvider = {
    id: '005190000042dRPAAY',
    name: 'Renan test Borges',
    nameWithSuffix: 'Renan test Borges',
    doxyLink: 'https://ec.concerthealth.io/',
    hasConcertSettingsManagerAccess: false,
    isSysAdmin: false,
    languages: 'English',
    managerId: null,
    maximumCaseload: 30.0,
    numOfActiveEpisodes: 19,
    numOfActiveEpisodesForPractice: 4,
    caseloadAllocation: 21,
    phone: '(919) 555-1000',
    photoUrl: 'https://concerthealth--develop2.sandbox.file.force.com/profilephoto/005/T',
    profileName: null,
    region: 'None',
    roleName: 'Product Manager',
    title: 'Senior Product Manager',
    targetCaseLoad: 90,
    timezone: 'America/Tijuana',
    generalSettings: null,
};

const mainProviderAvailability = {
    capacity: {
        timeslotsAvailableInNearFuture: 400,
    },
    acceptingNewPatients: true,
    engagementTypes: [
        'Standard Collaborative Care',
        'Patient Monitoring',
        'Employee Assistance Program',
        'Psychotherapy',
        'Attention Deficit-Hyperactivity',
        'Membership Collaborative Care',
    ],
    specialties: ['Ages 6-12'],
    isOnSitePractice: false,
    notAcceptingType: '',
    provider: mainProvider,
};

const secondaryProvider = {
    id: '0052M00000ATEvmQAH',
    name: 'Malia McFatridge',
    nameWithSuffix: 'Malia McFatridge BS, CSPO',
    doxyLink: 'https://ec.concerthealth.io/',
    hasConcertSettingsManagerAccess: false,
    isSysAdmin: false,
    languages: 'English',
    managerId: null,
    maximumCaseload: 30.0,
    numOfActiveEpisodes: 19,
    numOfActiveEpisodesForPractice: 4,
    caseloadAllocation: 21,
    phone: '(919) 555-1000',
    photoUrl: 'https://concerthealth--develop2.sandbox.file.force.com/profilephoto/005/T',
    profileName: null,
    region: 'None',
    roleName: 'Product Manager',
    title: 'Senior Product Manager',
    targetCaseLoad: 90,
    timezone: 'America/Tijuana',
    generalSettings: null,
};

const secondaryProviderAvailability = {
    capacity: {
        timeslotsAvailableInNearFuture: 567,
    },
    acceptingNewPatients: true,
    engagementTypes: [
        'Standard Collaborative Care',
        'Patient Monitoring',
        'Employee Assistance Program',
        'Psychotherapy',
        'Attention Deficit-Hyperactivity',
        'Membership Collaborative Care',
    ],
    specialties: ['Ages 18+'],
    isOnSitePractice: false,
    notAcceptingType: '',
    provider: secondaryProvider,
};

export const graphqlHandlers = [
    graphql.query<GetAuthenticationTokenQuery, GetAuthenticationTokenQueryVariables>(
        'getAuthenticationToken',
        (req, res, ctx) => {
            const signedToken =
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMDU4WTAwMDAwQXQwT05RQVoiLCJjaC5zZnRva2VuIjoiMDBEMDMwMDAwMDA0WDRYIUFRNEFRRnBFY0dLMEFJQXQyQ2xhUWRxY0VkcUJBLk9kS0lMb2J1TkNQUU5DUDNaQ3ExZlpCNVFoU09oUWtLa2ZNSHlkRGZ6R0guVU45bTJNci5EZVpkdkNXVlhkUjI1dSIsImNoLnNmSW5zdGFuY2VVcmwiOiJodHRwczovL2NvbmNlcnRoZWFsdGgtLWRldmVsb3AyLnNhbmRib3gubXkuc2FsZXNmb3JjZS5jb20iLCJjaC5zZkFwaVZlcnNpb24iOiJ2NTAuMCIsImNoLnNmSWRVcmwiOiJodHRwczovL3Rlc3Quc2FsZXNmb3JjZS5jb20vaWQvMDBEMDMwMDAwMDA0WDRYRUFVLzAwNThZMDAwMDBBdDBPTlFBWiIsImNoLnRpbWV6b25lIjoiQW1lcmljYS9Mb3NfQW5nZWxlcyIsImNoLm5hbWUiOiJEYXJyZWwgQW5kZXJzb24iLCJmYW1pbHlfbmFtZSI6IkFuZGVyc29uIiwiZ2l2ZW5fbmFtZSI6IkRhcnJlbCIsImp0aSI6ImVlMTkyMmZkLTg3M2EtNDliMy04MjBmLWEyYzJiOWY5NzlkNyIsImV4cCI6MTY4NjkzODU3OH0.fDCRUQH-1iW0wAKD0mturf9J-i6Tm_64f44g4FHjt7A';
            return res(ctx.data({ getAuthenticationToken: signedToken }));
        },
    ),
    graphql.query<ScheduleAvailabilityQuery, ScheduleAvailabilityQueryVariables>(
        'scheduleAvailability',
        (req, res, ctx) => {
            const { request } = req.variables;
            const providerAvailability =
                request.whoFilterType === AvailabilityWhoFilterType.Team
                    ? [secondaryProviderAvailability, mainProviderAvailability]
                    : [mainProviderAvailability];

            return res(
                ctx.data({
                    scheduleAvailability: [
                        {
                            date: request.startDateTime,
                            timeslots: [
                                {
                                    time: '08:15:00',
                                    providerAvailability,
                                },
                                {
                                    time: '12:00:00',
                                    providerAvailability,
                                },
                            ],
                        },
                    ],
                }),
            );
        },
    ),
    graphql.query<GetUserAvailabilitySettingsQuery, GetUserAvailabilitySettingsQueryVariables>(
        'getAvailabilitySettings',
        (req, res, ctx) => {
            return res(
                ctx.data({
                    getAvailabilitySettings: {
                        general: {
                            byDate: [],
                            byDay: [
                                {
                                    day: 'Monday',
                                    timeslots: [
                                        {
                                            startTime: '09:00:00',
                                            endTime: '17:00:00',
                                            dayOfWeek: 'Monday',
                                            userId: '0058Y00000At0ONQAZ',
                                            patientId: null,
                                            availabilityType: 'ByDay',
                                            id: 'a1M03000000d8TeEAI',
                                            category: 'General',
                                            dateOfAvailability: null,
                                            practice: null,
                                            modifiedByEC: false,
                                        },
                                    ],
                                },
                                {
                                    day: 'Tuesday',
                                    timeslots: [
                                        {
                                            startTime: '09:00:00',
                                            endTime: '17:00:00',
                                            dayOfWeek: 'Monday',
                                            userId: '0058Y00000At0ONQAZ',
                                            patientId: null,
                                            availabilityType: 'ByDay',
                                            id: 'a1M03000000d8TeEAI',
                                            category: 'General',
                                            dateOfAvailability: null,
                                            practice: null,
                                            modifiedByEC: false,
                                        },
                                    ],
                                },
                                {
                                    day: 'Wednesday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Thursday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Friday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Saturday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Sunday',
                                    timeslots: [],
                                },
                            ],
                        },
                    },
                }),
            );
        },
    ),
    /*
    We need to comment on this for scheduler-selector tests, depending on getPatientAvailabilitySettings,
    to pass. It seems like we had a conflict between the handlers with the same name for this specific case.
    graphql.query<GetPatientAvailabilitySettingsQuery, GetPatientAvailabilitySettingsQueryVariables>(
        'getPatientAvailabilitySettings',
        (req, res, ctx) => {
            console.log('this is the default patient avail');
            return res(
                ctx.data({
                    getAvailabilitySettings: {
                        general: {
                            byDate: [],
                            byDay: [
                                {
                                    day: 'Monday',
                                    timeslots: [
                                        {
                                            startTime: '09:00:00',
                                            endTime: '17:00:00',
                                            dayOfWeek: 'Monday',
                                            userId: '0058Y00000At0ONQAZ',
                                            patientId: 'patientTestId',
                                            availabilityType: 'ByDay',
                                            id: 'a1M03000000d8TeEAI',
                                            category: 'General',
                                            dateOfAvailability: null,
                                            practice: null,
                                            modifiedByEC: false,
                                        },
                                    ],
                                },
                                {
                                    day: 'Tuesday',
                                    timeslots: [
                                        {
                                            startTime: '09:00:00',
                                            endTime: '17:00:00',
                                            dayOfWeek: 'Monday',
                                            userId: '0058Y00000At0ONQAZ',
                                            patientId: 'patientTestId',
                                            availabilityType: 'ByDay',
                                            id: 'a1M03000000d8TeEAI',
                                            category: 'General',
                                            dateOfAvailability: null,
                                            practice: null,
                                            modifiedByEC: false,
                                        },
                                    ],
                                },
                                {
                                    day: 'Wednesday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Thursday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Friday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Saturday',
                                    timeslots: [],
                                },
                                {
                                    day: 'Sunday',
                                    timeslots: [],
                                },
                            ],
                        },
                    },
                }),
            );
        },
    ), */
];
