import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Card } from 'primereact/card';
import { selectUser } from '../user/selector';
import { SchedulerSubHeader } from './scheduler-sub-header';
import './scheduler.scss';
import { getLanguages } from '../languages/slice';
import {
    AvailabilityWhoFilterType,
    hydrateFilterParams,
    setAvailabilityFilter,
    changeFilterSelection,
} from './scheduler-filter/slice';
import { selectSchedulerFilter } from './scheduler-filter/selector';
import { SchedulerSelector } from './availability/scheduler-selector';
import { SchedulerConfirmationScreen } from './scheduler-confirmation-screen';
import { SchedulingRequest } from './slice';
import {
    AvailabilitySlotDetails,
    setRefineAvailabilitySelectedOptions,
    setRefineOptions,
    setSelectedSlot,
} from './availability/slice';
import { SchedulingDateFunctions } from '../../services/scheduling-date-fns';
import { buildSchedulingRequest } from './availability/availability-request-builder';
import { getEngagementTypes } from '../engagement-types/slice';
import { selectSelectedSlot } from './availability/selector';
import { baseTimeslotFilters } from './availability/scheduler-timeslots-refinement';

export enum SchedulingStepEnum {
    Scheduling,
    Confirmation,
}

const Scheduler: FunctionComponent = () => {
    const currentLocation = useLocation();
    const { episodeId, practiceId, userId, language } = QueryString.parse(currentLocation.search);
    const user = useSelector(selectUser);
    const filter = useSelector(selectSchedulerFilter);
    const selectedSlot = useSelector(selectSelectedSlot);
    const dispatch = useDispatch();
    const initialSchedulingRequest = buildSchedulingRequest(
        new Date(),
        30,
        SchedulingDateFunctions.getUsersTimezone(),
        '00:00',
    );
    const areFiltersEmpty = () => {
        return !episodeId && !practiceId && !userId && !language;
    };
    const [isSchedulingGrayedOut, setIsSchedulingGrayedOut] = useState(false);
    const [currentSchedulingStep, setSchedulingStep] = useState(SchedulingStepEnum.Scheduling);
    const [selectedSchedulingRequest, setSelectedSchedulingRequest] = useState(initialSchedulingRequest);
    const [appointmentFilterViewCollapsed, setAppointmentFilterViewCollapsed] = useState(true);

    useEffect(() => {
        dispatch(getLanguages());
        dispatch(getEngagementTypes());
        dispatch(
            hydrateFilterParams({
                episodeId,
                practiceId,
                loggedInUser: user,
                userId,
                language,
            }),
        );
        dispatch(changeFilterSelection(!areFiltersEmpty()));
        areFiltersEmpty() && onHeaderOpenClose();
    }, []);

    useEffect(() => {
        if (!filter.episode) {
            dispatch(setRefineAvailabilitySelectedOptions([]));
        } else if (filter.who === AvailabilityWhoFilterType.Team) {
            const { patientAge } = filter.episode;
            const automaticTeamFilters = ['AcceptingNewPatients', 'EngagementType'];
            const ageFilter = getAgeFilterForGivenAge(patientAge ?? 0);
            if (ageFilter) {
                automaticTeamFilters.push(ageFilter);
            }
            dispatch(setRefineAvailabilitySelectedOptions(automaticTeamFilters));
        } else {
            dispatch(setRefineAvailabilitySelectedOptions(['EngagementType']));
        }
        setSchedulingStep(SchedulingStepEnum.Scheduling);
    }, [filter]);

    const getAgeFilterForGivenAge = (age: number): string | null => {
        if (age >= 6 && age <= 12) {
            return baseTimeslotFilters.ages6To12.value;
        }
        if (age >= 13 && age <= 17) {
            return baseTimeslotFilters.ages13To17.value;
        }
        if (age >= 18) {
            return baseTimeslotFilters.ages18Plus.value;
        }
        return null;
    };

    const onHeaderOpenClose = () => {
        setIsSchedulingGrayedOut(appointmentFilterViewCollapsed);
        setAppointmentFilterViewCollapsed(!appointmentFilterViewCollapsed);
    };

    const onCalendarEventRequestComplete = (isSuccess: boolean) => {
        if (isSuccess) {
            dispatch(setRefineOptions([]));
            dispatch(setRefineAvailabilitySelectedOptions([]));
            const newfilter = { who: AvailabilityWhoFilterType.User };
            dispatch(setAvailabilityFilter(newfilter));
            onHeaderOpenClose();
        }
        setSchedulingStep(SchedulingStepEnum.Scheduling);
    };

    const onSlotSelected = (selectedSchedulingRequestParam: SchedulingRequest, slot: AvailabilitySlotDetails) => {
        setSelectedSchedulingRequest(selectedSchedulingRequestParam);
        dispatch(setSelectedSlot(slot));
        setSchedulingStep(SchedulingStepEnum.Confirmation);
    };

    const goBack = (step: SchedulingStepEnum) => {
        setSchedulingStep(step);
    };

    return (
        <div>
            <div className="sub-header">
                <SchedulerSubHeader
                    appointmentFilterViewCollapsed={appointmentFilterViewCollapsed}
                    onFilterPanelOpenClose={onHeaderOpenClose}
                />
            </div>
            <div className="app-container">
                <div className={isSchedulingGrayedOut ? 'overlay-gray visible' : 'overlay-gray'} />
                <Card className="scheduler-screen">
                    {currentSchedulingStep === SchedulingStepEnum.Scheduling ? (
                        <SchedulerSelector
                            onSlotSelected={onSlotSelected}
                            initialSchedulingRequest={selectedSchedulingRequest}
                        />
                    ) : (
                        <SchedulerConfirmationScreen
                            selectedSchedulingRequest={selectedSchedulingRequest}
                            episode={filter.episode}
                            onGoBack={() => goBack(SchedulingStepEnum.Scheduling)}
                            onCalendarEventRequestComplete={onCalendarEventRequestComplete}
                            users={selectedSlot.providers}
                        />
                    )}
                </Card>
            </div>
        </div>
    );
};

export default Scheduler;
