import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { FunctionComponent, useContext, useEffect } from 'react';
import './message-center.scss';
import { DateTime } from 'luxon';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import * as QueryString from 'query-string';
import { Loader } from 'concert-ui-library';
import { Conversation } from './conversation';
import { useGetConversationsQuery } from '../../services/graphql/generated';
import { NotificationContext } from '../../notification-context';

export const MessageCenter: FunctionComponent = () => {
    const currentLocation = useLocation();
    const history = useHistory();
    const notificationContext = useContext(NotificationContext);
    const { episodeId } = QueryString.parse(currentLocation.search);
    const {
        data: conversation,
        error: conversationError,
        isFetching,
    } = useGetConversationsQuery({
        request: {
            episodeId: episodeId as string,
            userId: '',
        },
    });
    const rowClassName = (rowData: Conversation) => {
        return rowData.unread ? 'unread-thread' : '';
    };
    const dateTemplate = (rowData: string) => {
        const fileDateTime = DateTime.fromISO(rowData, { zone: 'utc' });
        return fileDateTime.toFormat('LL/dd/yyyy: HH:mm');
    };
    const handleRowClick = (s: DataTableRowClickEvent) => {
        const rowData = s.data as Conversation;
        history.push({
            pathname: 'message-thread',
            search: `?conversationId=${rowData.id}`,
        });
    };
    const getClinicianName = () => conversation?.getConversations[0].patientName;

    const placeholderErrorMessage = 'Something Went wrong while fetching the conversations.';

    useEffect(() => {
        if (conversationError) {
            notificationContext?.showError(placeholderErrorMessage);
        }
    }, [conversationError]);

    return (
        <div className="app-container message-center-container">
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <h2>Messaging: {`${getClinicianName()}`}</h2>
                    <div className="new-conversation-btn">
                        <Button
                            icon="pi pi-plus"
                            className="p-button p-component p-button-primary"
                            data-testid="new-row"
                            onClick={() => history.push('message-thread')}
                        />
                    </div>
                    <Card>
                        <DataTable
                            value={conversation?.getConversations}
                            dataKey="id"
                            selectionMode="single"
                            breakpoint="200px"
                            sortField="nextContactDate"
                            sortOrder={-1}
                            onRowClick={handleRowClick}
                            rowClassName={rowClassName}
                        >
                            <Column
                                field="lastActivityDate"
                                sortable
                                body={(s: Conversation) => dateTemplate(s.lastActivityDate)}
                                header="Last Activity"
                            />
                            <Column field="Topic" sortable body={(s: Conversation) => s.topic} header="Topic" />
                        </DataTable>
                    </Card>
                </>
            )}
        </div>
    );
};
