import { createSlice } from '@reduxjs/toolkit';
import type { SlotButtonAction } from '../scheduler/availability/provider-selector';
import type { SlotForRender } from '../scheduler/availability/scheduler-transform';
import type { AvailabilityProviderDetails } from '../scheduler/availability/slice';
import type { ProviderCapacity } from '../../services/graphql/generated';

export interface User {
    first_name: string;
    last_name: string;
    initials: string;
    id: string;
    name: string;
    timezone: string;
    numOfActiveEpisodes?: number;
    numOfActiveEpisodesForPractice?: number;
    targetCaseLoad?: number;
    caseloadAllocation?: number;
    weeklyClinicalHours?: number;
    acceptingNewPatients?: boolean;
    notAcceptingType?: string;
    photoUrl?: string;
    region?: string;
    languages?: string;
    title?: string;
    roleName?: string;
    doxyLink?: string;
    phone?: string;
    managerId?: string;
    isSysAdmin?: boolean;
    isOnSitePractice?: boolean;
    hasConcertSettingsManagerAccess?: boolean;
    slots?: Array<SlotForRender | SlotButtonAction>;
    refProvider?: AvailabilityProviderDetails;
    nameWithSuffix?: string;
    capacity?: ProviderCapacity;
}

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
    },
    reducers: {
        changeUser(state, action) {
            state.user = action.payload;
        },
    },
});

export const { changeUser } = userSlice.actions;

export const { reducer } = userSlice;
