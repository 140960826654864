import { api } from './concert-base-api';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    AWSDate: string;
    AWSDateTime: string;
    AWSEmail: string;
    AWSIPAddress: any;
    AWSJSON: string;
    AWSPhone: any;
    AWSTime: string;
    AWSTimestamp: string;
    AWSURL: string;
    BigInt: any;
    Double: any;
};

export type AvailabilityInput = {
    endDateTime: Scalars['AWSDateTime'];
    episodeId?: InputMaybe<Scalars['String']>;
    episodePracticeId?: InputMaybe<Scalars['String']>;
    languageKey?: InputMaybe<Scalars['String']>;
    practiceId?: InputMaybe<Scalars['String']>;
    providers: Array<InputMaybe<ProviderUserInput>>;
    slotDuration: Scalars['Int'];
    startDateTime: Scalars['AWSDateTime'];
    tzTimeZone: Scalars['String'];
    whoFilterType: Scalars['Int'];
};

export type AvailabilitySettingByDate = {
    __typename?: 'AvailabilitySettingByDate';
    date: Scalars['AWSDate'];
    timeslots: Array<AvailabilitySettingTimeslot>;
};

export type AvailabilitySettingByDay = {
    __typename?: 'AvailabilitySettingByDay';
    day: Scalars['String'];
    timeslots: Array<AvailabilitySettingTimeslot>;
};

export type AvailabilitySettingTimeslot = {
    __typename?: 'AvailabilitySettingTimeslot';
    availabilityType: Scalars['String'];
    category: Scalars['String'];
    dateOfAvailability?: Maybe<Scalars['AWSDate']>;
    dayOfWeek?: Maybe<Scalars['String']>;
    endTime: Scalars['String'];
    id: Scalars['String'];
    modifiedByEC: Scalars['Boolean'];
    patientId?: Maybe<Scalars['String']>;
    practice?: Maybe<Practice>;
    startTime: Scalars['String'];
    userId?: Maybe<Scalars['String']>;
};

export type AvailabilitySettingTimeslotInput = {
    availabilityType?: InputMaybe<Scalars['String']>;
    category?: InputMaybe<Scalars['String']>;
    dateOfAvailability?: InputMaybe<Scalars['AWSDate']>;
    dayOfWeek?: InputMaybe<Scalars['String']>;
    endTime?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    modifiedByEC?: InputMaybe<Scalars['Boolean']>;
    patientId?: InputMaybe<Scalars['String']>;
    practice?: InputMaybe<PracticeInput>;
    startTime?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['String']>;
};

export type AvailabilitySettings = {
    __typename?: 'AvailabilitySettings';
    general?: Maybe<AvailabilitySettingsCategoryGroup>;
    site?: Maybe<AvailabilitySettingsCategoryGroup>;
};

export type AvailabilitySettingsCategoryGroup = {
    __typename?: 'AvailabilitySettingsCategoryGroup';
    byDate: Array<AvailabilitySettingByDate>;
    byDay: Array<AvailabilitySettingByDay>;
};

export enum AvailabilityWhoFilterType {
    Team = 'TEAM',
    User = 'USER',
}

export type AvailabilityWindowInput = {
    appointmentId: Scalars['String'];
};

export type CalendarEvent = {
    __typename?: 'CalendarEvent';
    endDateTime?: Maybe<Scalars['AWSDateTime']>;
    eventType?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    ownerId?: Maybe<Scalars['String']>;
    reminderStatus?: Maybe<Scalars['String']>;
    startDateTime?: Maybe<Scalars['AWSDateTime']>;
};

export type Conversation = {
    __typename?: 'Conversation';
    clinicianName: Scalars['String'];
    creationDate: Scalars['AWSDateTime'];
    episodeId: Scalars['String'];
    id: Scalars['String'];
    lastActivityDate: Scalars['AWSDateTime'];
    messages?: Maybe<Array<Maybe<ConversationMessage>>>;
    patientName: Scalars['String'];
    topic: Scalars['String'];
    unread: Scalars['Boolean'];
};

export type ConversationInput = {
    episodeId: Scalars['String'];
    message: ConversationMessageInput;
    topic: Scalars['String'];
};

export type ConversationMessage = {
    __typename?: 'ConversationMessage';
    conversationId: Scalars['String'];
    createDate: Scalars['AWSDateTime'];
    draft: Scalars['Boolean'];
    id: Scalars['String'];
    internalDocumentation: Scalars['String'];
    message: Scalars['String'];
    minutes?: Maybe<Scalars['Int']>;
    recipientId: Scalars['String'];
    recipientType: MessageUserType;
    senderId: Scalars['String'];
    senderType: MessageUserType;
};

export type ConversationMessageInput = {
    conversationId?: InputMaybe<Scalars['String']>;
    createDate: Scalars['AWSDateTime'];
    draft?: InputMaybe<Scalars['Boolean']>;
    internalDocumentation?: InputMaybe<Scalars['String']>;
    message: Scalars['String'];
    minutes?: InputMaybe<Scalars['Int']>;
    recipientId: Scalars['String'];
};

export type DateAvailability = {
    __typename?: 'DateAvailability';
    date: Scalars['String'];
    timeslots?: Maybe<Array<Maybe<Timeslot>>>;
};

export type GeneralSettings = {
    __typename?: 'GeneralSettings';
    isAcceptingNewPatients?: Maybe<Scalars['Boolean']>;
    notAcceptingType?: Maybe<Scalars['String']>;
    userConcertSetting?: Maybe<UserConcertSetting>;
};

export type GetAvailabilitySettingsInput = {
    userIds: Array<Scalars['String']>;
};

export type GetCalendarFileInput = {
    description?: InputMaybe<Scalars['String']>;
    duration: Scalars['Int'];
    location?: InputMaybe<Scalars['String']>;
    startDateTime: Scalars['AWSDateTime'];
    title: Scalars['String'];
};

export type GetConversationMessagesInput = {
    conversationId: Scalars['String'];
};

export type GetConversationsInput = {
    episodeId: Scalars['String'];
    userId: Scalars['String'];
};

export type GetPatientResourceDataInput = {
    fileId: Scalars['String'];
};

export type GetPatientResourcesInput = {
    episodeId: Scalars['String'];
};

export enum MessageUserType {
    Clinician = 'CLINICIAN',
    Patient = 'PATIENT',
}

export type Mutation = {
    __typename?: 'Mutation';
    createConversation?: Maybe<Conversation>;
    reschedule?: Maybe<CalendarEvent>;
    sendConversationMessage?: Maybe<ConversationMessage>;
    updateAvailabilitySettings?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateConversationArgs = {
    conversationRequest: ConversationInput;
};

export type MutationRescheduleArgs = {
    rescheduleRequest: RescheduleInput;
};

export type MutationSendConversationMessageArgs = {
    messageInput: SendConversationMessageinput;
};

export type MutationUpdateAvailabilitySettingsArgs = {
    request: UpdateAvailabilitySettingsInput;
};

export type PatientResource = {
    __typename?: 'PatientResource';
    carePlanName?: Maybe<Scalars['String']>;
    fileCreationDate?: Maybe<Scalars['String']>;
    fileId?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
};

export type Practice = {
    __typename?: 'Practice';
    id: Scalars['String'];
    name: Scalars['String'];
};

export type PracticeInput = {
    id: Scalars['String'];
    name: Scalars['String'];
};

export type ProviderAvailability = {
    __typename?: 'ProviderAvailability';
    acceptingNewPatients?: Maybe<Scalars['Boolean']>;
    capacity?: Maybe<ProviderCapacity>;
    engagementTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    isOnSitePractice?: Maybe<Scalars['Boolean']>;
    notAcceptingType?: Maybe<Scalars['String']>;
    provider?: Maybe<User>;
    specialties?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProviderCapacity = {
    __typename?: 'ProviderCapacity';
    minutesAvailableInNearFuture?: Maybe<Scalars['Int']>;
    timeslotsAvailableInNearFuture?: Maybe<Scalars['Int']>;
};

export type ProviderUserInput = {
    doxyLink?: InputMaybe<Scalars['String']>;
    hasConcertSettingsManagerAccess?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Scalars['String']>;
    isSysAdmin?: InputMaybe<Scalars['Boolean']>;
    languages?: InputMaybe<Scalars['String']>;
    managerId?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    nameWithSuffix?: InputMaybe<Scalars['String']>;
    numOfActiveEpisodes?: InputMaybe<Scalars['Int']>;
    numOfActiveEpisodesForPractice?: InputMaybe<Scalars['Int']>;
    phone?: InputMaybe<Scalars['String']>;
    photoUrl?: InputMaybe<Scalars['String']>;
    profileName?: InputMaybe<Scalars['String']>;
    region?: InputMaybe<Scalars['String']>;
    roleName?: InputMaybe<Scalars['String']>;
    targetCaseLoad?: InputMaybe<Scalars['Int']>;
    timezone?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    availabilityWindow?: Maybe<Array<Scalars['AWSDateTime']>>;
    getAuthenticationToken?: Maybe<Scalars['String']>;
    getAvailabilitySettings?: Maybe<AvailabilitySettings>;
    getCalendarFile?: Maybe<Scalars['String']>;
    getConversationMessages: Array<ConversationMessage>;
    getConversations: Array<Conversation>;
    getPatientResourceData?: Maybe<Scalars['String']>;
    getPatientResources?: Maybe<Array<Maybe<PatientResource>>>;
    rescheduleAvailability?: Maybe<Array<Maybe<DateAvailability>>>;
    scheduleAvailability?: Maybe<Array<Maybe<DateAvailability>>>;
};

export type QueryAvailabilityWindowArgs = {
    request: AvailabilityWindowInput;
};

export type QueryGetAvailabilitySettingsArgs = {
    request: GetAvailabilitySettingsInput;
};

export type QueryGetCalendarFileArgs = {
    request: GetCalendarFileInput;
};

export type QueryGetConversationMessagesArgs = {
    request: GetConversationMessagesInput;
};

export type QueryGetConversationsArgs = {
    request: GetConversationsInput;
};

export type QueryGetPatientResourceDataArgs = {
    request: GetPatientResourceDataInput;
};

export type QueryGetPatientResourcesArgs = {
    request: GetPatientResourcesInput;
};

export type QueryRescheduleAvailabilityArgs = {
    request: AvailabilityInput;
};

export type QueryScheduleAvailabilityArgs = {
    request: AvailabilityInput;
};

export type RescheduleAvailabilityInput = {
    originalAppointmentId: Scalars['String'];
    tzTimeZone: Scalars['String'];
};

export type RescheduleInput = {
    appointmentStartDateTime: Scalars['AWSDateTime'];
    originalAppointmentId: Scalars['String'];
};

export type Timeslot = {
    __typename?: 'Timeslot';
    providerAvailability?: Maybe<Array<Maybe<ProviderAvailability>>>;
    time?: Maybe<Scalars['AWSTime']>;
};

export type UpdateAvailabilitySettingsInput = {
    settingsToBeAdded: Array<AvailabilitySettingTimeslotInput>;
    settingsToBeDeleted: Array<AvailabilitySettingTimeslotInput>;
    settingsToBeModified: Array<AvailabilitySettingTimeslotInput>;
};

export type User = {
    __typename?: 'User';
    caseloadAllocation?: Maybe<Scalars['Int']>;
    doxyLink?: Maybe<Scalars['String']>;
    generalSettings?: Maybe<GeneralSettings>;
    hasConcertSettingsManagerAccess?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['String']>;
    isSysAdmin?: Maybe<Scalars['Boolean']>;
    languages?: Maybe<Scalars['String']>;
    managerId?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    nameWithSuffix?: Maybe<Scalars['String']>;
    numOfActiveEpisodes?: Maybe<Scalars['Int']>;
    numOfActiveEpisodesForPractice?: Maybe<Scalars['Int']>;
    phone?: Maybe<Scalars['String']>;
    photoUrl?: Maybe<Scalars['String']>;
    profileName?: Maybe<Scalars['String']>;
    region?: Maybe<Scalars['String']>;
    roleName?: Maybe<Scalars['String']>;
    targetCaseLoad?: Maybe<Scalars['Int']>;
    timezone?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type UserConcertSetting = {
    __typename?: 'UserConcertSetting';
    acceptingNewPatients?: Maybe<Scalars['Boolean']>;
    activeEpisodes?: Maybe<Scalars['Float']>;
    engagementTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    specialties?: Maybe<Array<Maybe<Scalars['String']>>>;
    targetCaseLoad?: Maybe<Scalars['Float']>;
    userId?: Maybe<Scalars['String']>;
};

export type SendConversationMessageinput = {
    conversationId: Scalars['String'];
    message: ConversationMessageInput;
};

export type GetAuthenticationTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuthenticationTokenQuery = { __typename?: 'Query'; getAuthenticationToken?: string | null };

export type ScheduleAvailabilityQueryVariables = Exact<{
    request: AvailabilityInput;
}>;

export type ScheduleAvailabilityQuery = {
    __typename?: 'Query';
    scheduleAvailability?: Array<{
        __typename?: 'DateAvailability';
        date: string;
        timeslots?: Array<{
            __typename?: 'Timeslot';
            time?: string | null;
            providerAvailability?: Array<{
                __typename?: 'ProviderAvailability';
                acceptingNewPatients?: boolean | null;
                engagementTypes?: Array<string | null> | null;
                isOnSitePractice?: boolean | null;
                notAcceptingType?: string | null;
                specialties?: Array<string | null> | null;
                capacity?: { __typename?: 'ProviderCapacity'; timeslotsAvailableInNearFuture?: number | null } | null;
                provider?: {
                    __typename?: 'User';
                    id?: string | null;
                    name?: string | null;
                    nameWithSuffix?: string | null;
                    doxyLink?: string | null;
                    hasConcertSettingsManagerAccess?: boolean | null;
                    isSysAdmin?: boolean | null;
                    languages?: string | null;
                    managerId?: string | null;
                    numOfActiveEpisodes?: number | null;
                    numOfActiveEpisodesForPractice?: number | null;
                    caseloadAllocation?: number | null;
                    phone?: string | null;
                    photoUrl?: string | null;
                    profileName?: string | null;
                    region?: string | null;
                    roleName?: string | null;
                    title?: string | null;
                    targetCaseLoad?: number | null;
                    timezone?: string | null;
                    generalSettings?: {
                        __typename?: 'GeneralSettings';
                        isAcceptingNewPatients?: boolean | null;
                        notAcceptingType?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
};

export type GetConversationsQueryVariables = Exact<{
    request: GetConversationsInput;
}>;

export type GetConversationsQuery = {
    __typename?: 'Query';
    getConversations: Array<{
        __typename?: 'Conversation';
        creationDate: string;
        episodeId: string;
        id: string;
        lastActivityDate: string;
        patientName: string;
        topic: string;
        unread: boolean;
    }>;
};

export type GetUserAvailabilitySettingsQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetUserAvailabilitySettingsQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
        site?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type GetGeneralUserAvailabilitySettingsByDateQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetGeneralUserAvailabilitySettingsByDateQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type GetPatientAvailabilitySettingsQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetPatientAvailabilitySettingsQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type GetGeneralUserAvailabilitySettingsByDayQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetGeneralUserAvailabilitySettingsByDayQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type UpdateAvailabilitySettingsMutationVariables = Exact<{
    request: UpdateAvailabilitySettingsInput;
}>;

export type UpdateAvailabilitySettingsMutation = {
    __typename?: 'Mutation';
    updateAvailabilitySettings?: boolean | null;
};

export const GetAuthenticationTokenDocument = `
    query getAuthenticationToken {
  getAuthenticationToken
}
    `;
export const ScheduleAvailabilityDocument = `
    query scheduleAvailability($request: AvailabilityInput!) {
  scheduleAvailability(request: $request) {
    date
    timeslots {
      time
      providerAvailability {
        capacity {
          timeslotsAvailableInNearFuture
        }
        acceptingNewPatients
        engagementTypes
        isOnSitePractice
        notAcceptingType
        specialties
        provider {
          id
          name
          nameWithSuffix
          doxyLink
          hasConcertSettingsManagerAccess
          isSysAdmin
          languages
          managerId
          numOfActiveEpisodes
          numOfActiveEpisodesForPractice
          caseloadAllocation
          phone
          photoUrl
          profileName
          region
          roleName
          title
          targetCaseLoad
          timezone
          generalSettings {
            isAcceptingNewPatients
            notAcceptingType
          }
        }
      }
    }
  }
}
    `;
export const GetConversationsDocument = `
    query getConversations($request: GetConversationsInput!) {
  getConversations(request: $request) {
    creationDate
    episodeId
    id
    lastActivityDate
    patientName
    topic
    unread
  }
}
    `;
export const GetUserAvailabilitySettingsDocument = `
    query getUserAvailabilitySettings($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
    site {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const GetGeneralUserAvailabilitySettingsByDateDocument = `
    query getGeneralUserAvailabilitySettingsByDate($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const GetPatientAvailabilitySettingsDocument = `
    query getPatientAvailabilitySettings($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const GetGeneralUserAvailabilitySettingsByDayDocument = `
    query getGeneralUserAvailabilitySettingsByDay($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const UpdateAvailabilitySettingsDocument = `
    mutation updateAvailabilitySettings($request: UpdateAvailabilitySettingsInput!) {
  updateAvailabilitySettings(request: $request)
}
    `;

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAuthenticationToken: build.query<GetAuthenticationTokenQuery, GetAuthenticationTokenQueryVariables | void>({
            query: (variables) => ({ document: GetAuthenticationTokenDocument, variables }),
        }),
        scheduleAvailability: build.query<ScheduleAvailabilityQuery, ScheduleAvailabilityQueryVariables>({
            query: (variables) => ({ document: ScheduleAvailabilityDocument, variables }),
        }),
        getConversations: build.query<GetConversationsQuery, GetConversationsQueryVariables>({
            query: (variables) => ({ document: GetConversationsDocument, variables }),
        }),
        getUserAvailabilitySettings: build.query<
            GetUserAvailabilitySettingsQuery,
            GetUserAvailabilitySettingsQueryVariables
        >({
            query: (variables) => ({ document: GetUserAvailabilitySettingsDocument, variables }),
        }),
        getGeneralUserAvailabilitySettingsByDate: build.query<
            GetGeneralUserAvailabilitySettingsByDateQuery,
            GetGeneralUserAvailabilitySettingsByDateQueryVariables
        >({
            query: (variables) => ({ document: GetGeneralUserAvailabilitySettingsByDateDocument, variables }),
        }),
        getPatientAvailabilitySettings: build.query<
            GetPatientAvailabilitySettingsQuery,
            GetPatientAvailabilitySettingsQueryVariables
        >({
            query: (variables) => ({ document: GetPatientAvailabilitySettingsDocument, variables }),
        }),
        getGeneralUserAvailabilitySettingsByDay: build.query<
            GetGeneralUserAvailabilitySettingsByDayQuery,
            GetGeneralUserAvailabilitySettingsByDayQueryVariables
        >({
            query: (variables) => ({ document: GetGeneralUserAvailabilitySettingsByDayDocument, variables }),
        }),
        updateAvailabilitySettings: build.mutation<
            UpdateAvailabilitySettingsMutation,
            UpdateAvailabilitySettingsMutationVariables
        >({
            query: (variables) => ({ document: UpdateAvailabilitySettingsDocument, variables }),
        }),
    }),
});

export { injectedRtkApi as api };
export const {
    useGetAuthenticationTokenQuery,
    useLazyGetAuthenticationTokenQuery,
    useScheduleAvailabilityQuery,
    useLazyScheduleAvailabilityQuery,
    useGetConversationsQuery,
    useLazyGetConversationsQuery,
    useGetUserAvailabilitySettingsQuery,
    useLazyGetUserAvailabilitySettingsQuery,
    useGetGeneralUserAvailabilitySettingsByDateQuery,
    useLazyGetGeneralUserAvailabilitySettingsByDateQuery,
    useGetPatientAvailabilitySettingsQuery,
    useLazyGetPatientAvailabilitySettingsQuery,
    useGetGeneralUserAvailabilitySettingsByDayQuery,
    useLazyGetGeneralUserAvailabilitySettingsByDayQuery,
    useUpdateAvailabilitySettingsMutation,
} = injectedRtkApi;
