import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        auth: false,
        token: '',
    },
    reducers: {
        changeAuthState(state, action) {
            state.auth = action.payload;
            state.token = action.payload.token;
        },
        unAuthenticate(state) {
            state.auth = false;
            state.token = '';
        },
        unAuthenticateUserInitiate(state) {
            state.auth = false;
            state.token = '';
        },
    },
});

export const { changeAuthState, unAuthenticate, unAuthenticateUserInitiate } = authSlice.actions;

export const { reducer } = authSlice;
