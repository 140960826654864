import { createSlice } from '@reduxjs/toolkit';
import { ApiCallStatus } from '../../services/api-call-status';

export type Episode = {
    name: string;
    id: string;
    practiceName: string;
    providerName: string;
    practiceState: string;
    practiceId: string;
    timezone: string;
    patientEmail: string;
    patientId: string;
    appointmentNotificationsEmailOptOut: boolean;
    ownerId: string;
    ownerName: string;
    patientAge?: number;
    patientLanguage: string;
    engagementType: string;
};

export const initialState = {
    data: [] as Episode[],
    error: null,
    status: ApiCallStatus.idle,
};

const episodesSlice = createSlice({
    name: 'episodes',
    initialState,
    reducers: {
        getEpisodes(state, action) {
            state.status = ApiCallStatus.loading;
            return state;
        },
        clearEpisodesFilter(state) {
            state.data = [];
        },
        setEpisodesSuccess(state, action) {
            state.status = ApiCallStatus.succeeded;
            state.data = action.payload.data;
        },
        setEpisodesFailure(state, action) {
            state.status = ApiCallStatus.failed;
            state.error = action.payload.error;
        },
    },
});

export const { getEpisodes, setEpisodesSuccess, setEpisodesFailure, clearEpisodesFilter } = episodesSlice.actions;

export const { reducer } = episodesSlice;
