import { FunctionComponent, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Button } from 'primereact/button';
import { Conversation } from './conversation';
import { messagesMock } from './message-center.mock';
import { MessageForm, UserType } from './message-sender';
import { ConversationViewer } from './conversation-viewer';
import { NotificationContext } from '../../notification-context';

export const ConversationCenter: FunctionComponent = () => {
    const currentLocation = useLocation();
    const history = useHistory();
    const notificationContext = useContext(NotificationContext);
    const { conversationId } = QueryString.parse(currentLocation.search);
    const threadsMock: Conversation | undefined = messagesMock.find((s: Conversation) => s.id === conversationId);
    const messageHandlerSubmission = (formData: MessageForm) => {
        if (formData.isValid) {
            notificationContext?.showSuccess('Message submitted');
        } else {
            notificationContext?.showError('Please fill in the required fields for the message');
        }
    };
    return (
        <div>
            <ConversationViewer
                conversation={threadsMock ?? null}
                messageSenderComponentProps={{
                    userType: UserType.clinician,
                    isNewMessage: !conversationId,
                    handleMessageSubmission: messageHandlerSubmission,
                }}
            />
            <Button label="Go back to message center" link onClick={() => history.push('message-center')} />
        </div>
    );
};
