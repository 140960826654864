import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { getGraphQLConfig, GraphQLSettings } from '../../config';
import { unAuthenticate } from '../../containers/auth/slice';
import { isDevelopment } from '../../util';
import { ApiException, AppSyncAuthorizationHeaders, DEFAULT_GRAPHQL_URL, TOKEN_EXPIRED_MESSAGE } from './constants';

const graphQLConfig: GraphQLSettings = getGraphQLConfig();
export const isSessionExpiredError = (message?: string): boolean => !!message?.startsWith(TOKEN_EXPIRED_MESSAGE);
export const isUnauthorizedException = (message?: string): boolean =>
    message?.includes(ApiException.Unauthorized) ?? false;

const baseGraphQLQuery = graphqlRequestBaseQuery({
    url: graphQLConfig?.graphQLUrl ?? DEFAULT_GRAPHQL_URL,
    prepareHeaders: (headers, api: any) => {
        const { auth } = api.getState();
        const { token } = auth;

        if (isDevelopment()) {
            headers.set(AppSyncAuthorizationHeaders.Authorization, `${token}`);
            headers.set(AppSyncAuthorizationHeaders.ApiKey, `${graphQLConfig.apiKey}`);
        } else if (token) headers.set(AppSyncAuthorizationHeaders.Authorization, `${token}`);

        return headers;
    },
});

export const api = createApi({
    reducerPath: 'graphQLApi',
    baseQuery: async (args, api: any, extraOptions): Promise<any> => {
        const result = await baseGraphQLQuery(args, api, extraOptions);

        if (isSessionExpiredError(result?.error?.message) || isUnauthorizedException(result?.error?.message)) {
            result.error = undefined;

            const { auth } = api.getState();
            if (auth?.token) {
                await api.dispatch(unAuthenticate());
            }
        }
        return result;
    },
    endpoints: () => ({}),
});
