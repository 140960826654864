import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react';
import { InputText } from 'primereact/inputtext';

import classNames from 'classnames';
import { EpisodeSelector } from '../episodes/episode-selector';
import { Episode } from '../episodes/slice';

export enum UserType {
    clinician,
    patient,
}
export interface MessageSenderProps {
    isNewMessage: boolean;
    userType: UserType;
    handleMessageSubmission: (formData: MessageForm) => void;
}
export interface MessageForm {
    message: string | undefined;
    topic: string | undefined;
    internalDocumentation: string;
    episode: Episode | null;
    minutes: string | undefined;
    isValid: boolean;
}
export const MessageSender: FunctionComponent<MessageSenderProps> = ({
    isNewMessage,
    userType,
    handleMessageSubmission,
}) => {
    const [form, setForm] = useState<MessageForm>({
        episode: null,
    } as MessageForm);
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(isNewMessage);

    const validateForm = (): boolean => {
        if (!form.message) return false;

        const clinicianBaseValidation: boolean = !form.minutes || !form.internalDocumentation;
        const newMessageBaseValidation: boolean = !form.topic || !form.episode;
        const isClinicianUser = userType === UserType.clinician;

        if (!isNewMessage && isClinicianUser && clinicianBaseValidation) return false;
        if (isNewMessage && isClinicianUser && clinicianBaseValidation && newMessageBaseValidation) return false;
        if (isNewMessage && !isClinicianUser && newMessageBaseValidation) return false;

        return true;
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        const validatedForm = { ...form, isValid: validateForm() };
        handleMessageSubmission(validatedForm);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };
    const renderForm = (): JSX.Element[] => {
        const formData: JSX.Element[] = [];
        if (isNewMessage) {
            formData.push(
                <>
                    <div className="p-inputgroup flex-1">
                        <EpisodeSelector
                            onSelectedEpisode={(e) => setForm({ ...form, episode: e })}
                            selectedEpisodeParam={form?.episode}
                        />
                    </div>
                    <div className="p-inputgroup flex-1">
                        <InputText
                            value={form?.topic}
                            placeholder="Topic"
                            name="topic"
                            onChange={handleInputChange}
                            className={classNames({
                                'p-invalid': isFormSubmitted && (!form.topic || form.topic.trim() === ''),
                            })}
                        />
                    </div>
                </>,
            );
        }
        formData.push(
            <>
                <div className="p-inputgroup flex-1">
                    <InputTextarea
                        rows={5}
                        cols={30}
                        value={form.message}
                        name="message"
                        placeholder="Message"
                        onChange={handleInputChange}
                        className={classNames({
                            'p-invalid': isFormSubmitted && (!form.message || form.message.trim() === ''),
                        })}
                    />
                </div>
                <div className="p-inputgroup flex-1">
                    <InputTextarea
                        rows={3}
                        cols={30}
                        name="internalDocumentation"
                        value={form.internalDocumentation}
                        placeholder="Internal Documentation"
                        onChange={handleInputChange}
                        className={classNames({
                            'p-invalid':
                                isFormSubmitted &&
                                (!form.internalDocumentation || form.internalDocumentation.trim() === ''),
                        })}
                    />
                </div>
                <div className="p-inputgroup flex-1 message-send-buton">
                    <InputText
                        onChange={handleInputChange}
                        value={form.minutes}
                        placeholder="Minutes"
                        type="number"
                        name="minutes"
                        min={1}
                        max={120}
                        className={classNames({
                            'p-invalid': isFormSubmitted && !form.minutes,
                        })}
                    />{' '}
                    <Button label="Save Draft" /> <Button label="Submit" type="submit" />
                </div>
            </>,
        );
        return formData;
    };

    return (
        <div className="message-sender-container">
            <form onSubmit={handleSubmit}>
                {showForm ? (
                    renderForm()
                ) : (
                    <div className="new-conversation-btn">
                        <Button
                            icon="pi pi-plus"
                            className="p-button p-component"
                            data-testid="new-row"
                            onClick={() => setShowForm(true)}
                        />
                    </div>
                )}
            </form>
        </div>
    );
};
